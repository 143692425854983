export const SET_INITIALIZED = 'SET_INITIALIZED';

export const FETCH_EDUCATIONS = 'FETCH_EDUCATIONS';
export const SET_EDUCATIONS = 'SET_EDUCATIONS';

export const FETCH_LANGUAGES = 'FETCH_LANGUAGES';
export const SET_LANGUAGES = 'SET_LANGUAGES';

export const FETCH_POSITIONS = 'FETCH_POSITIONS';
export const SET_POSITIONS = 'SET_POSITIONS';

export const FETCH_WORKHISTORY = 'FETCH_WORKHISTORY';
export const SET_WORKHISTORY = 'SET_WORKHISTORY';
